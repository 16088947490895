<template>
    <base-nav container-classes="container-fluid" :show="true"
              class="custom-navbar">

        <template slot="brand">
            <div class="navbar-wrapper">
                <router-link to="/shop-admin/" class="mr-0 mr-md-2">
                    <img src="../../assets/img/xeo-logo-white.png" class="logo" alt="Xeoscript"/>
                </router-link>
            </div>
        </template>

        <b-navbar-nav class="mr-auto">
            <b-nav-item to="/shop-admin/" v-if="currentUser.role ==='Shop Admin'">
                <span class="nav-link-inner--text text-sm">
                    <i class="ni ni-single-02"></i>
                    Home
                </span>
            </b-nav-item>
            <b-nav-item to="/shop-admin/categories/" v-if="currentUser.role === 'Shop Admin'">
                <a class="nav-link-inner--text text-sm">
                    <i class="ni ni-bullet-list-67"></i>
                    Categories
                </a>
            </b-nav-item>
            <b-nav-item to="/shop-admin/catalog/" v-if="currentUser.role === 'Shop Admin'">
                <a class="nav-link-inner--text text-sm">
                    <i class="ni ni-collection"></i>
                    Catalog
                </a>
            </b-nav-item>
            <b-nav-item :to="'/shop-admin/orders/'+ currentUser.shop_id  +'/'" v-if="currentUser.role ==='Shop Admin'">
                <span class="nav-link-inner--text text-sm">
                    <i class="ni ni-single-02"></i>
                    Orders
                </span>
            </b-nav-item>

        </b-navbar-nav>

        <UserProfile></UserProfile>
    </base-nav>
</template>
<script>
import { BaseNav } from 'argon/src/components';
import UserProfile from '@/layouts/components/navbar/UserProfile';
import { mapGetters } from 'vuex';

export default {
    components: {
        BaseNav,
        UserProfile

    },
    props: {
        type: {
            type: String,
            default: 'default', // default|light
            description: 'Look of the dashboard navbar. Default (Green) or light (gray)'
        }
    },
    computed: {
        routeName () {
            const { name } = this.$route;
            return this.capitalizeFirstLetter(name);
        },
        ...mapGetters(['currentUser'])

    },
    data () {
        return {
            activeNotifications: false,
            showMenu: false,
            searchModalVisible: false,
            searchQuery: ''
        };
    },
    methods: {
        capitalizeFirstLetter (string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        toggleNotificationDropDown () {
            this.activeNotifications = !this.activeNotifications;
        },
        closeDropDown () {
            this.activeNotifications = false;
        },
        toggleSidebar () {
            this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
        },
        hideSidebar () {
            this.$sidebar.displaySidebar(false);
        }
    }
};
</script>
<style lang="scss">

.custom-navbar {
    border-bottom: 5px solid #fec43d !important;
    background: #25a7e1;;

    .nav-link {
        color: white !important;
    }

    img.logo {
        max-height: 25px;
    }

}

</style>
